import * as React from 'react';
import {useEffect, useState} from 'react';
import Map, {Marker} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import finishline from './finishline.png'
import nowifi from './nowifi.png'

import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function App() {
  const [viewState, setViewState] = useState({
    longitude: 25.0060976,
    latitude: 60.2193211,
    zoom: 13.5
  });

  const [countdown, setCountdown] = useState(999);

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById('cong');
      el.classList.add('shown');
    }, 2000)
    setTimeout(() => {
      const el = document.getElementById('youare');
      el.classList.add('shown');
    }, 3500)
    setTimeout(() => {
      const el = document.getElementById('you');
      el.classList.add('shown');
    }, 3500)
    setTimeout(() => {
      const el = document.getElementById('are');
      el.classList.add('shown');
    }, 3750)
    setTimeout(() => {
      const el = document.getElementById('approaching');
      el.classList.add('shown');
    }, 4000)
    setTimeout(() => {
      const el = document.getElementById('amazing');
      el.classList.add('shown');
    }, 5000)
    setTimeout(() => {
      const el = document.getElementById('finish');
      el.classList.add('shown');
    }, 5000)
    setTimeout(() => {
      const el = document.getElementById('from');
      el.classList.add('shown');
    }, 10000)
    setTimeout(() => {
      const el = document.getElementById('msg');
      el.classList.add('hidden');
    }, 15000)
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      navigator.geolocation.getCurrentPosition(pos => {
        console.log(pos.coords);
        setViewState({
          ...viewState,
          longitude: pos.coords.longitude,
          latitude: pos.coords.latitude,
        })
      });
    }, 5000);

    return () => {
      clearInterval(intervalId);
    }
  }, [viewState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const mod = 210;
      const timestamp = new Date().getTime();
      const secondstamp = Math.floor(timestamp / 1000);
      const secs = secondstamp % mod;
      setCountdown(mod - secs);
      const el = document.getElementById('connection-problem');
      const war = document.getElementById('warning');
      if (secs > 24 && secs < 30 && !war.classList.contains('shown')) {
        war.classList.add('shown');
      } else if ((secs <= 24 || secs >= 30) && war.classList.contains('shown')) {
        war.classList.remove('shown');
      }
      if (secs < 30 && !el.classList.contains('hidden')) {
        el.classList.add('hidden');
      } else if (secs >= 30 && el.classList.contains('hidden')) {
        el.classList.remove('hidden');
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  });

  return (<>
    <div id="msg">
      <div id="cong">Congratulations!</div>
      <div id="youare">
        <span id="you">you </span>
        <span id="are">are </span>
        <span id="approaching">approaching</span>
      </div>
      <div id="amazing">the amazing race</div>
      <div id="finish">Finish Line</div>
      <div id="from">...GO EAST</div>
    </div>
    <div id="warning">
      <img alt="" src={nowifi} />
    </div>
    <div id="connection-problem">
      <div>CONNECTION PROBLEM</div>
      <img alt="" src={nowifi} />
      <div>Trying again in {countdown}</div>
    </div>
    <div id="north"><span>NORTH</span></div>
    <Map
      mapLib={import('mapbox-gl')}
      {...viewState}
      style={{width: '100vw', height: '100vh'}}
      mapStyle="mapbox://styles/realtuukka/clllz4mg400al01r57bv278lb"
      mapboxAccessToken="pk.eyJ1IjoicmVhbHR1dWtrYSIsImEiOiJjbGxsdmNuNTUybHViM2ZtZ2Q3YWtsMWQxIn0.lfinMy_YBLSB8Cs1A0QPTA"
    >
      <Marker longitude={25.0155792} latitude={60.22398}>
        <img alt="" src={finishline} height="28px"/>
      </Marker>
      <Marker longitude={viewState.longitude} latitude={viewState.latitude}>
        <div class="you"><span>YOU</span></div>
      </Marker>
    </Map>
  </>);
}

export default App;
